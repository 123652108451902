<template>
  <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg" @click="$emit('click')">
    <path opacity="0.5" d="M6.5 0C2.91588 0 0 2.92437 0 6.51889C0 10.9798 5.81686 17.5287 6.06452 17.8053C6.29714 18.0651 6.70328 18.0647 6.93548 17.8053C7.18314 17.5287 13 10.9798 13 6.51889C12.9999 2.92437 10.0841 0 6.5 0ZM6.5 9.79873C4.69674 9.79873 3.22972 8.3274 3.22972 6.51889C3.22972 4.71038 4.69678 3.23909 6.5 3.23909C8.30322 3.23909 9.77024 4.71042 9.77024 6.51893C9.77024 8.32744 8.30322 9.79873 6.5 9.79873Z" fill="black"/>
  </svg>
</template>

<script>
    export default {
        name: "IconMapMarker"
    }
</script>

<style scoped>

</style>
