<template>
  <div
    v-if="office && office.id"
    class="offer__header"
    :class="{offer__header_scrolled: isScroll, 'offer__header__no-img': withOutImg }"
  >
    <img
      v-if="!withOutImg && (organization.logo && organization.logo.path)"
      :src="organization.logo.path"
      :alt="organization.title"
      class="header__logo"
      :class="{'header__logo_hidden': isScroll}"
    >
    <img
      v-else
      :alt="organization.title"
      src="@/assets/img/organization-logo.png"
      class="header__logo"
    >
    <div
      class="header__info-group"
      :class="{'header__info-group__no-img': withOutImg}"
    >
      <h1
        class="info-group__title"
        :class="{'info-group__title_scrolled': isScroll}"
      >
        {{ office.title }}
      </h1>

      <p
        class="info-group__info"
        :class="{'offer__header__address_hidden': isScroll}"
      >
        <IconMapMarker />
        <span class="offer__header__address">
          {{ office.address }}
        </span>
      </p>
    </div>
    <div
      class="header__calculation-group"
      :class="{'header__calculation-group_scrolled': isScroll}"
    >
      <h1
        v-show="isScroll"
        class="calculation-group__sum"
        :class="[{'calculation-group__sum_scrolled': isScroll, 'calculation-group__sum_not-have': checkSumByPrice}]"
      >
        {{ office.amount | numberToBeautifulNumber }} ₽
      </h1>

      <p
        class="calculation-group__count"
        :class="{'calculation-group__count_scrolled': isScroll}"
      >
        {{ office.availableOffersNumber }} {{ $tc('unitList.offer', office.availableOffersNumber) }}

        <IconPlus
          v-if="enableRefillOffer"
          width="30"
          height="30"
          style="cursor: pointer"
          @click.native="showModalOffer"
        />
      </p>

      <h1
        v-show="!isScroll"
        class="calculation-group__sum"
        :class="{'calculation-group__sum_not-have': checkSumByPrice}"
      >
        <AppTextMoney :money="office.amount" />
        {{ $t('unitList.currency.RU') }}

        <IconPlus
          v-if="enableRefillMoney"
          width="30"
          height="30"
          style="cursor: pointer"
          @click.native="addBalance"
        />
      </h1>

      <!--      <AppButton
        class="header__button offer__header__button"
        :class="{'offer__header__button_scrolled': isScroll}"
        @click.native="addBalance"
      >
        {{ $t('offerList.header.button.add') }}
      </AppButton>-->
    </div>
  </div>
</template>

<script>
import IconMapMarker from '@/components/common/icons/IconMapMarker'
import IconPlus from '@/components/common/icons/IconPlus'
import AppTextMoney from '@/components/common/AppTextMoney'
export default {
  name: 'OfferListHeader',
  components: { AppTextMoney, IconPlus, IconMapMarker },
  props: {
    office: {
      type: Object
    },
    isScroll: {
      type: Boolean,
    },
    withOutImg: {
      type: Boolean,
      default: false
    },
    price: {
      type: Number,
      default: 0
    },
    enableRefillMoney: {
      type: Boolean,
      default: true
    },
    enableRefillOffer: {
      type: Boolean,
      default: true
    }
  },
  computed: {

    organization () {
      return this.office.organization
    },

    checkSumByPrice () {
      return this.price > this.office.amount
    }
  },
  methods: {
    showModalOffer () {
      this.$store.dispatch('popup/showModal', { key: 'showOfferBuyForm' })
    },
    addBalance () {
      this.$store.dispatch('popup/showModal', { key: 'showMoneyAddForm' })
    }
  }
}
</script>

<style lang="sass" scoped>
  .offer
    &__header
      display: flex
      background-color: #ffffff
      padding: 30px 35px 30px 30px
      align-items: center
      border: 1.5px solid rgba(0, 0, 0, 0.1)
      box-shadow: 0px 5px 15px rgba(117, 117, 117, 0.1)
      border-radius: 6px
      &_scrolled
        padding: 17px
      &__address
        margin-left: 10px
        text-wrap: normal
      &__button
        &_scrolled
          margin-left: auto
    &__filter-top
      display: flex

      &__left-group
        height: 50px

  .header
    &__logo
      margin-right: 20px
      height: 88px

    &__info-group
      display: flex
      flex-direction: column

    &__calculation-group
      display: flex
      flex-direction: column
      margin-left: auto
      padding-left: 25px
      border-left: 1px solid rgba(117, 117, 117, 0.21)
      &_scrolled
        flex-direction: row
        align-items: center
  .calculation-group
    &__sum
      display: flex
      align-items: center
      margin-bottom: 10px
      svg
        flex-shrink: 0
        margin-left: auto
      &_not-have
        color: red
      &_scrolled
          margin-bottom: 0
          margin-right: 15px
    &__count
      @extend %button-text
      color: rgba(0, 0, 0, 0.6)
      display: flex
      align-items: center
      svg
        flex-shrink: 0
        margin-left: auto
      &_scrolled
        margin-right: 20px
  .info-group
    &__title
      margin-bottom: 20px
      &_scrolled
        margin-bottom: 0

    &__info
      @extend %subheader__text

  .offer__header__no-img
    padding: 16px
    border: 0
    box-shadow: 0 0 0 rgba(117, 117, 117, 0.1)
    border-bottom: $default_border_bottom

  .header__logo_hidden, .offer__header__address_hidden
    display: none
</style>
